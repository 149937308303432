const initialState = {};

export const newsReducer = (state = initialState, { type, payload }) => {
  switch (true) {
    case 1: {
      return state;
    }
    default:
      return state;
  }
};
